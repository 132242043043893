<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Flag id:"
          label-for="flag-input-id"
        >
          <b-form-input
            id="flag-input-id"
            v-model="idValue"
            :disabled="isAppLoading || !fetchedResources.flags || !fetchedResources.commercialflags || edition"
            :debounce="500"
            :state="idState"
            aria-describedby="flag-input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="flag-input-id-feedback">
            {{ errorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
          label-for="flag-input-display-name"
        >
          <b-form-input
            id="flag-input-display-name"
            v-model.trim="displayNameValue"
            :disabled="isAppLoading || !fetchedResources.flags"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Description:"
          label-for="flag-input-description"
        >
          <b-form-textarea
            id="flag-input-description"
            v-model="descriptionValue"
            placeholder="Enter a description"
            rows="3"
            no-resize
            :debounce="500"
            :disabled="isAppLoading || !fetchedResources.flags"
          />
        </b-form-group>
        <b-form-group
          class="col-7 mb-2 pl-0"
          label="Tenants:"
          label-for="brand-input-tenant"
        >
          <Multiselect
            id="brand-input-tenant"
            v-model="tenantValues"
            placeholder="Select tenant"
            multiple
            track-by="id"
            label="displayName"
            :options="tenantsState"
            :disabled="!fetchedResources.tenants"
            :show-labels="false"
          />
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'
  import { RESOURCES } from '@/service/ResourceService'
  import {
    getDocumentReference,
  } from '@/service/FirebaseService'

  const initialData = {
    idValue: null,
    displayNameValue: null,
    descriptionValue: null,
    tenantValues: null,
  }
  export default {
    name: 'FlagCreation',

    mixins: [DictionnariesCreationMixin],

    props: {
      description: {
        type: String,
        default: null,
      },
      tenants: {
        type: Array,
        default: null,
      },
    },

    data() {
      return {
        ...initialData,
        initialData,
        stateKey: 'flags',
        idValidRegex: new RegExp(/^[A-Za-z-_]+$/),
        defaultErrorMessage:
          'Id must only contains letters without accentuation (lowercase or uppercase) and -_ characters.',
      }
    },

    computed: {
      ...mapState({
        tenantsState: (state) => state.tenants,
        fetchedResources: (state) => state.fetchedResources,
      }),

      finalObject() {
        return {
          id: this.idValue,
          displayName: this.displayNameValue,
          description: this.descriptionValue,
          tenants: this.tenantValues?.map((tenant) =>
                getDocumentReference(`tenants/${tenant.id}`),
              ) ?? []
        }
      },
    },

    async mounted() {
      if (this.edition) {
        this.idValue = this.id
        this.displayNameValue = this.displayName
        this.descriptionValue = this.description
        this.tenantValues = this.tenants
      }

      await this.loadResources({ resourceNames: [RESOURCES.FLAGS, RESOURCES.TENANTS] })
    },

    methods: {
      ...mapActions(['loadResources']),
    },
  }
</script>
