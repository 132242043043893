<template>
  <BaseAdminPage
    :title="title"
    :components="components"
  />
</template>

<script>
  import BaseAdminPage from '@/components/admin/BaseAdminPage'
  import CustomerCreationVue from '@/components/admin/mainCollections/CustomerCreation'
  import CollectionManagementVue from '../components/admin/mainCollections/CollectionManagement.vue'
  import FlagCreationVue from '../components/admin/mainCollections/FlagCreation.vue'
  import CountryCreationVue from '../components/admin/mainCollections/CountryCreation.vue'
  import CriteriaCreationVue from '../components/admin/mainCollections/CriteriaCreation.vue'
  import HeroIngredientCreationVue from '../components/admin/mainCollections/HeroIngredientCreation.vue'
  import CommercialFlagCreationVue from '../components/admin/mainCollections/CommercialFlagCreation.vue'

  import { mapGetters } from 'vuex'
  import { RIGHTS } from '@/constants/UserConstants'
  import { TILE_TAGS_COLOR } from '@/constants/ColorConstants'
  import BrandCreationVue from '../components/admin/mainCollections/BrandCreation.vue'
  import CRMCodeCreationVue from '../components/admin/mainCollections/CRMCodeCreation.vue'

  export default {
    name: 'MainCollectionAdmin',

    components: {
      BaseAdminPage,
    },

    computed: {
      ...mapGetters(['canUserDo']),

      title() {
        return this.$route.meta?.displayName ?? 'Main Collection Admin'
      },

      components() {
        return [
          {
            id: 'BrandsManagement',
            title: 'Brands Management',
            component: CollectionManagementVue,
            props: {
              name: 'brand',
              tagsValues: ['tenants'],
              collectionName: 'brands',
              modalComponent: BrandCreationVue,
              detailsFunction: this.brandsDetailsFunction,
              disallowCreate: !this.canUserDo(RIGHTS.CREATE_BRAND),
              lazy: true,
            },
          },
          {
            id: 'FlagsManagement',
            title: 'Flags Management',
            component: CollectionManagementVue,
            props: {
              name: 'flag',
              tagsValues: ['tenants'],
              collectionName: 'flags',
              detailsFunction: this.flagDetailsFunction,
              modalComponent: FlagCreationVue,
              lazy: true,
            },
          },
          {
            id: 'CommercialFlagsManagement',
            title: 'Commercial Flags Management',
            component: CollectionManagementVue,
            props: {
              name: 'Commercial Flag',
              tagsValues: ['tenants'],
              collectionName: 'commercialflags',
              detailsFunction: this.flagDetailsFunction,
              modalComponent: CommercialFlagCreationVue,
              lazy: true,
            },
          },
          {
            id: 'CustomersManagement',
            title: 'Customers Management',
            component: CollectionManagementVue,
            props: {
              name: 'customer',
              collectionName: 'customers',
              detailsFunction: this.customerDetailsFunction,
              modalComponent: CustomerCreationVue,
              itemHasCRUD: (item) => {
                return item?.tenant?.id === 'modiface'
              },
              lazy: true,
            },
          },
          {
            id: 'CountriesManagement',
            title: 'Countries Management',
            component: CollectionManagementVue,
            props: {
              name: 'country',
              collectionName: 'countries',
              modalComponent: CountryCreationVue,
              showFlag: true,
              lazy: true,
            },
          },
          {
            id: 'CriteriasManagement',
            title: 'Criterias Management',
            component: CollectionManagementVue,
            props: {
              name: 'criteria',
              collectionName: 'criterias',
              modalComponent: CriteriaCreationVue,
              detailsFunction: this.criteriaDetailsFunction,
              editFormatFunction: this.criteriaEditFormatFunction,
              lazy: true,
            },
          },
          {
            id: 'HeroIngredientsManagement',
            title: 'Hero Ingredients Management',
            component: CollectionManagementVue,
            props: {
              name: 'Hero Ingredient',
              tagsValues: ['tenants'],
              collectionName: 'heroingredients',
              modalComponent: HeroIngredientCreationVue,
              lazy: true,
              detailsFunction: this.flagDetailsFunction,
            },
          },
          ...(this.canUserDo(RIGHTS.DICTONARIES_CRM_CODES)
            ? [
                {
                  id: 'CRMManagement',
                  title: 'CRM Management',
                  component: CollectionManagementVue,
                  props: {
                    name: 'CRM Code',
                    collectionName: 'crmcodes',
                    modalComponent: CRMCodeCreationVue,
                    hasEdition: false,
                    lazy: true,
                  },
                },
              ]
            : []),
        ]
      },
    },

    methods: {
      brandsDetailsFunction: ({ description, tenants, id }) => ({
        id,
        ...(description && { description }),
        ...(tenants?.length > 0 && {
          tenants: tenants.map((tenant) => {
            return {
              color: TILE_TAGS_COLOR,
              id: tenant.id,
              label: tenant.displayName,
            }
          }),
        }),
      }),

      flagDetailsFunction: ({ description, tenants }) => ({
        ...(description && { description }),
        ...(tenants?.length > 0 && {
          tenants: tenants.map((tenant) => {
            return {
              color: TILE_TAGS_COLOR,
              id: tenant.id,
              label: tenant.displayName,
            }
          }),
        }),
      }),

      customerDetailsFunction: ({ tenant }) => ({
        ...(tenant && { tenant: tenant?.displayName ?? tenant?.id }),
      }),

      criteriaDetailsFunction: ({ id, bmdm_ref, bmdm_value, color }) => ({
        id,
        ...(bmdm_ref && { bmdmRef: bmdm_ref }),
        ...(bmdm_value && { bmdmValue: bmdm_value }),
        ...(color && { color: color }),
      }),

      criteriaEditFormatFunction: ({
        id,
        label,
        bmdm_ref,
        bmdm_payload,
        bmdm_interaction_payload,
        bmdm_value,
        color,
      }) => ({
        id,
        label,
        ...(bmdm_payload && { bmdmPayload: bmdm_payload }),
        ...(bmdm_interaction_payload && {
          bmdmInteractionPayload: bmdm_interaction_payload,
        }),
        ...(bmdm_value && { bmdmValue: bmdm_value }),
        ...(bmdm_ref && { bmdmRef: bmdm_ref }),
        ...(color && { color: color }),
      }),
    },
  }
</script>
